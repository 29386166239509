import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import api from 'helpers/api';
import { IconRemove, IconEdit } from 'shared/Icons';
import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import { getDepartmentShifts, getPaginatedDepartmentShifts, editShift, saveShift, deleteShift, getDepartments } from './actions';

import ShiftModal from './components/ShiftModal';
import ExceptionsTab from './components/ExceptionsTab';

const Shifts = ({ companyId, t, locationId }) => {
  const [tableData, setTableData] = useState({
    shifts: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [selectedAscDesc, setSelectedAscDesc] = useState('asc');
  const [selectedSort, setSelectedSort] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [newData, setNewData] = useState(null);
  const [showShiftModal, setShowShiftModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    showConfirmationDialog: false,
    deleteData: null,
  });

  const fetchShifts = async () => {
    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const filters = `&order_by=${asc}${selectedSort || 'department__name,order'}`;
    const res = await getDepartmentShifts(companyId, filters);
    setTableData({
      shifts: get(res, 'data.results', []),
      next: get(res, 'data.next', []),
      previous: get(res, 'data.previous', []),
      count: get(res, 'data.count', []),
      isLoading: false,
    });
  };

  const fetchPaginatedShifts = async (url) => {
    const res = await getPaginatedDepartmentShifts(url);
    setTableData({
      shifts: get(res, 'data.results', []),
      next: get(res, 'data.next', []),
      previous: get(res, 'data.previous', []),
      count: get(res, 'data.count', []),
      isLoading: false,
    });
  };

  useEffect(() => {
    getDepartments(locationId)
      .then((res) => { setDepartments(get(res, 'data.results', [])); })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchShifts();
  }, [selectedAscDesc, selectedSort]);

  const handleSorting = (sortData) => {
    setSelectedSort(sortData.id.replace(/\./g, '__'));
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const hoursAndMinutes = (value) => {
    return <div style={{ margin: 'auto' }}>{value.split(':').slice(0, 2).join(':')}</div>;
  };

  const handleShowShiftModal = (show, data) => {
    setShowShiftModal(show);
    setNewData(data);
  };

  const handleSaveShift = (shift) => {
    setTableData({ ...tableData, isLoading: true });

    if (shift.id) {
      editShift(companyId, shift)
        .then(() => {
          fetchShifts();
          handleShowShiftModal(false, null);
        });
    } else {
      Promise.all(shift.department.map((department) => saveShift(companyId, { ...shift, department: department.id })))
        .then(() => {
          fetchShifts();
          handleShowShiftModal(false, null);
        });
    }
  };

  const handleShowConfirmationModal = (e, shift) => {
    e.stopPropagation();
    setConfirmationModal({ showConfirmationDialog: true, deleteData: shift });
  };

  const handleDeleteShift = () => {
    setTableData({ ...tableData, isLoading: true });

    deleteShift(companyId, confirmationModal?.deleteData)
      .then(() => {
        fetchShifts();
        setConfirmationModal({ showConfirmationDialog: false, deleteData: null });
      });
  };

  const exportToExcel = () => {
    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const filters = `&order_by=${asc}${selectedSort || 'department__name,order'}`;

    api
      .get(`/api/v1/shifts/department_shifts/?company=${companyId}${filters}&format=xlsx&limit=${tableData?.count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Shifts.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.shifts.table_column_shift_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row && row.value ? row.value : '-'),
      width: 200,
    },
    {
      Header: () => <span>{t('page_content.human_resources.shifts.shiftModal.modal_row_shift_department')}</span>,
      accessor: 'department.name',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.shifts.shiftModal.modal_row_shift_sequence')}</span>,
      accessor: 'order',
      width: 150,
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.shifts.table_column_start')}</span>,
      accessor: 'begin_time',
      width: 130,
      Cell: (row) => (row && row.value ? hoursAndMinutes(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.shifts.table_column_end')}</span>,
      accessor: 'end_time',
      width: 130,
      Cell: (row) => (row && row.value ? hoursAndMinutes(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.shifts.table_column_begin_break')}</span>,
      accessor: 'begin_break',
      width: 130,
      Cell: (row) => (row && row.value ? hoursAndMinutes(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.shifts.table_column_end_break')}</span>,
      accessor: 'end_break',
      width: 130,
      Cell: (row) => (row && row.value ? hoursAndMinutes(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.shifts.table_column_number_of_employees')}</span>,
      accessor: 'employees',
      width: 150,
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => '',
      width: 75,
      sortable: false,
      Cell: (row) => (
      <Button onClick={() => handleShowShiftModal(true, row?.original)}>
        <IconEdit
          height="14px"
          width="14px"
          fill="#4285F4"
        />
      </Button>),
    },
    {
      Header: () => '',
      width: 75,
      sortable: false,
      Cell: (row) => <Button type="delete" onClick={(e) => handleShowConfirmationModal(e, row.original)}><IconRemove width="12px" height="12px" /></Button>,
    },
  ];

  return (
    <Tabs>
    <TabList>
      <Tab>{t('page_content.human_resources.shifts.shifts_tab')}</Tab>
      <Tab>{t('page_content.human_resources.shifts.exceptions_tab')}</Tab>
    </TabList>

    <TabPanel>
      {showShiftModal ?
        <ShiftModal
          closeModal={() => { handleShowShiftModal(false, null); }}
          shifts={tableData?.shifts || []}
          deleteShift={handleDeleteShift}
          saveShift={handleSaveShift}
          departments={departments}
          locationId={locationId}
          isOpen={showShiftModal}
          shift={newData}
        /> : null}
        <div style={{ display: 'flex', alignItems: 'baseline', width: '100%', justifyContent: 'flex-end', paddingBottom: '15px' }}>
          <Button
            type="add"
            onClick={() => handleShowShiftModal(true, null)}
          >
            {t('page_content.human_resources.shifts.button_add_shift')}
          </Button>
          <Button style={{ marginLeft: '10px' }} type="export" onClick={exportToExcel}>{t('page_content.human_resources.rating.individual_goals_tab.button_export_to_excel')}</Button>
        </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={tableColumns}
        data={tableData?.shifts || []}
        loading={tableData.isLoading}
        noDataText=" "
        showPagination={false}
        minRows={0}
        defaultPageSize={100}
        selectedRow={null}
        defaultSorted={[{ id: 'department.name', desc: false }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <TableButtons next={tableData.next} previous={tableData.previous} fetchFunction={fetchPaginatedShifts} count={tableData.count} />

      <ConfirmationModal
        itemName={confirmationModal?.deleteData?.name || ''}
        showModal={confirmationModal?.showConfirmationDialog}
        handleCloseModal={() => setConfirmationModal({ showConfirmationDialog: false, deleteData: null })}
        handleConfirmModal={handleDeleteShift}
        type="warning"
      />
    </TabPanel>

    <TabPanel>
      <ExceptionsTab
        companyId={companyId}
        departments={departments}
      />
    </TabPanel>
  </Tabs>
  );
};

Shifts.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default (withRouter(withTranslation()(Shifts)));
